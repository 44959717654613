@font-face {
  font-family: "Digital Numbers Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Digital Numbers Regular"),
    url("DigitalNumbers-Regular.woff") format("woff");
}
@font-face {
  font-family: "DS Digital";
  font-style: normal;
  font-weight: normal;
  src: local("DS Digital"), url("DS-DIGI.TTF") format("truetype");
}

.ss-page,
.ss-box-container {
  min-height: 65vh !important;
}

.ss-schedule-item {
  position: relative;
  overflow: hidden;
}

.ss-box-container {
  display: block;
  height: auto !important;
  padding-bottom: 35px;
}

.ss-box-filters {
  margin-bottom: 15px;
  background: linear-gradient(
    91.18deg,
    #41d88e -10.3%,
    #4dffa8 29.85%,
    #41d88e 66.66%,
    #4dffa8 101.23%
  );
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 15px;
}

.ss-box-filters .filter-item {
  width: 100%;
}

.ss-box-filters .filter-item:last-child {
  margin-right: 0;
  margin-left: auto;
}

.ss-box-filters .filter-item select {
  background: transparent;
  color: rgba(58, 58, 58, 1);
  text-transform: uppercase;
  padding: 5px 15px;
  border: none;
  box-shadow: none;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
}

.ss-box-filters .filter-item select:hover,
.ss-box-filters .filter-item select:focus,
.ss-box-filters .filter-item select:active {
  outline: none;
}

.ss-box-filters .filter-item button.ss-box-filter-btn {
  background: linear-gradient(148.81deg, #848484 -48.94%, #1e1e1e 143.41%);
  color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 18px;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: 0;
  display: block;
}

.ss-schedule-item {
  background: linear-gradient(144.74deg, #848484 -24.73%, #1e1e1e 95.14%);
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 15px;
}

.ss-schedule-item .team-left,
.ss-schedule-item .team-right,
.ss-schedule-item .match-info {
  flex: 1;
}

.ss-schedule-item .team-left h4,
.ss-schedule-item .team-right h4 {
  font-weight: 200;
}

.ss-schedule-item .match-info {
  flex: 2;
}

.ss-schedule-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto auto;
  gap: 25px;
  margin-top: 30px;
}

.ss-schedule-items.player-items {
  grid-template-columns: repeat(1, 1fr);
}

.ss-schedule-item.player-item {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  font-size: 25px;
}

.ss-schedule-item.player-item h4,
.ss-schedule-item.player-item span {
  width: 100%;
  margin: 0;
}

.ss-schedule-item.player-item .sno {
  max-width: 50px;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  line-height: 34.13px;
  letter-spacing: 0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  flex: 1;
}

.ss-schedule-item.player-item .player-name {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  line-height: 36.57px;
  letter-spacing: 0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 500;
  flex: 3;
}

.ss-schedule-item.player-item .university {
  font-weight: 300;
  line-height: 38.13px;
  letter-spacing: 0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(77, 255, 168, 1);
  flex: 2;
}

.ss-schedule-item.player-item .score {
  font-family: "DS Digital";
  font-size: 60px;
  font-weight: 400;
  line-height: 51.64px;
  letter-spacing: 0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-left: auto;
  margin-right: 0;
  text-align: right;
  flex: 1;
  display: flex;
  align-items: center;
}

.ss-schedule-item .team-left h4,
.ss-schedule-item .team-right h4 {
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 400;
  line-height: 30.48px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
}

.ss-schedule-item .match-info {
  flex: 2 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ss-schedule-item .match-info .head {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ss-schedule-item .match-info .foot .match-status {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 700;
  line-height: 15.85px;
  text-align: center;
  text-transform: uppercase;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.group-name {
  display: none;
}

.ss-schedule-item .match-info .foot .match-status.scheduled {
  color: #d9ff39;
}

.ss-schedule-item .match-info .foot .match-status.started {
  color: #4cffa7;
}

.ss-schedule-item .match-info .foot .match-status.ended {
  color: rgb(213, 47, 53);
}

.ss-schedule-item .match-info .head span {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 700;
  line-height: 15.85px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(165, 165, 165, 1);
}

.ss-schedule-item .match-info .middle {
  font-family: "DS Digital";
  /* font-family: "Digital Numbers Regular"; */
  font-size: 60px;
  font-weight: 500;
  /* font-weight: 400; */
  line-height: 51.64px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  width: 100%;
  align-items: center;
  /* max-width: 150px; */
  max-width: fit-content;
  padding: 5px 0;
}

.ss-schedule-item .match-info .middle span {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.ss-schedule-item .match-info .middle span:not(:first-child):not(:last-child) {
  width: 20px;
  max-width: 20px;
}

.ss-schedule-item.player-item .score span.label {
  display: none;
}


.ss-page {
  width: 100%;
}

.fanup-table {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  border-color: #999;
}

.fanup-table *{
  border-color: #333;
}

.fanup-table td{ 
  background: #111;
  color: #fff;
}

.fanup-table th{
  background: #42D98F;
  color: #333;
}

.result-group-name {
  margin: 35px 0;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fanup-score-table td:not(:first-child),
.fanup-score-table th:not(:first-child){
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .ss-schedule-items {
    grid-template-columns: repeat(1, 1fr);
  }

  .ss-schedule-item,
  .ss-box-filters {
    flex-direction: column;
  }

  .ss-schedule-item .team-left h4,
  .ss-schedule-item .team-right h4 {
    margin: 10px 0;
  }

  .ss-box {
    padding: 0 15px;
  }

  .ss-box-filters .filter-item:last-child {
    margin: 0 auto;
  }

  .ss-box-filters .filter-item button.ss-box-filter-btn {
    margin: 0 auto;
    margin-top: 10px;
  }

  .ss-schedule-item.player-item .sno {
    position: absolute;
    left: 15px;
    font-size: 60px;
    top: 60px;
    opacity: 0.5;
    z-index: 0;
  }

  .ss-schedule-item.player-item {
    padding-left: 90px;
  }

  .ss-schedule-item.player-item .score {
    text-align: left;
    font-size: 15px;
  }

  .ss-schedule-item.player-item .player-name,
  .ss-schedule-item.player-item .university {
    padding-left: 0px;
    font-size: 20px;
  }

  .ss-schedule-item.player-item .score span.label {
    display: block;
    flex: 0;
  }
}
